import React from "react";
import { graphql } from "gatsby";
import useTitleContext from "../../context/useTitleContext";
import "./Article.scss";
import BackBtn from "../../components/BackBtn/BackBtn";
import Seo from "../../components/seo";

function Article({ data }) {
  const markdownRemark = data.markdownRemark;
  const firstHeading =
    markdownRemark.html.match(/<h..*?>([^<>]+)<\/h.>/)[1] || null;
  const title = markdownRemark?.frontmatter?.h1 || firstHeading;
  useTitleContext(title);

  return (
    <>
      <Seo title={title} description={markdownRemark.html}></Seo>
      <BackBtn></BackBtn>
      <article
        className="article container"
        dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
      ></article>
    </>
  );
}

export const query = graphql`
  query MdArticle($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        h1
      }
    }
  }
`;

export default Article;
